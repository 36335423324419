.remove_margin {
    margin:0;
    padding:0;
}

.login {
    width: 320px;
    background: white;
    margin: 0;
    position: absolute;
    top: 55%;
    left: 65%;
    transform: translate(-50%, -50%);
    padding: 20px;
    font-family:Montserrat !important;
}

.login_header {
    color:#fff;
    text-align:center;
    font-size:28%;
}

.login_form h3 {
    text-align:left;
    color:#fff;
}

.login_form {
    box-sizing:border-box;
    padding-top:15px;
    padding-bottom:10%;
    margin:5% auto;
    text-align:center;
}

.login input[type="text"],
.login input[type="password"],  .login input[type="number"]  {
    max-width:400px;
    width: 100%;
    line-height:3em;
    font-family: Montserrat;
    margin:1em 0em;
    border-radius:5px;
    border:2px solid #f2f2f2;
    outline:none;
    padding-left:10px;
}

.login_btn {
    height: 40px;
    background: #f05a28;
    border: unset;
    border-radius: 3px;
    color: white;
    text-transform: uppercase;
    cursor: pointer;
    margin: 5px 0px;
    max-width: 400px;
    width: 100%;
    margin-left:7px;
}

.login_btn:focus {
    outline: none;
    box-shadow: none;
}

.sign_up{
    color:#f2f2f2;
    margin-left:-70%;
    cursor:pointer;
    text-decoration:underline;
}

.no_access {
    color:#E86850;
    margin:20px 0px 20px -57%;
    text-decoration:underline;
    cursor:pointer;
}

.try_again {
    color:#f2f2f2;
    text-decoration:underline;
    cursor:pointer;
}

.color_orange{
    color: #f05a28 !important;
}

/*Media Querie*/
@media  only screen and (min-width : 150px) and (max-width : 530px){
    .login_form h3 {
        text-align:center;
        margin:0;
    }
    .sign_up, .no_access {
        margin:10px 0;
    }
    .login_button {
        margin-bottom:10px;
    }
}

.rem_con{
    width: 84%;
    height: 3vh;
    margin: auto;
    text-align: center;
    padding: 18px;
}

.logo_img {
    width: 170px;
    margin-top: -75px;
    position: absolute;
    margin-left: 72px;
    border-radius: 11px
}